import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";
import moment from "moment";
import React, { useState, useMemo, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../../configs/apiRequest";
import Loading from "../../../components/Loading";
import NoData from "../../../components/NoData";
import Report from "../../../components/Report";
import { useTranslation } from "react-i18next";
import { StoreContext } from "../../../stores";
import ReportHistory from "../../../components/ReportHistory";
import bad from "../../../assets/images/bad.png";
import { AREPORT } from "../../../apis/report";

function TeacherReport() {
    const { me, selectedClass } = useContext(StoreContext);

    const { t } = useTranslation();
    const today = moment().format("YYYY-MM-DD")
    const [modal, setModal] = useState({
        open: false,
        initData: null,
        type: "CREATE",
    });
    const handleOpenModal = () => setModal((prev) => ({ ...prev, open: true, initData: {}, type: "CREATE" }));

    const handleCloseModal = () => {
        setModal((prev) => ({ ...prev, open: false }));
    };

    const { data: reportData, isLoading } = useQuery(
        [`teacher_report${selectedClass?.id}`],
        async () => {
            // const response = await handleFetchApi(
            //     AREPORT.search({ params: `?teacher_id=${me?.id}&course_id=${selectedClass?.courseId}&report_date=${today}` })
            // );
            if (me?.role === "ROLE_TEACHER")
                return await handleFetchApi(AREPORT.search({ params: `?teacher_id=${me?.id}&course_id=${selectedClass?.courseId}&report_date=${today}` })).then(
                    (response) => response?.data[0]
                );
            else return await handleFetchApi(AREPORT.getByHomeTeacher({ params: `?report_date=${today}` })).then((response) => response?.data[0]);
        },
        {
            staleTime: 300000,
            cacheTime: 300000,
            refetchOnWindowFocus: false,
        }
    );
    const formattedDate = useMemo(() => moment().format("DD/MM/YYYY"), []);
    return (
        <div className="loading_container" style={{ opacity: reportData?.is_confirmed ? 1 : 0.8 }}>
            <Typography.Title level={4} className="flex__between__center">
                {t("date")} {formattedDate} {reportData && !reportData?.is_confirmed && "( Chờ duyệt )"}
                <Button onClick={handleOpenModal} icon={<PlusOutlined />} type="primary" className="--md">
                    {t("create_today_comment")}
                </Button>
            </Typography.Title>

            {isLoading ? (
                <Loading />
            ) : reportData ? (
                <Report close={handleCloseModal} mode="VIEW" initData={reportData} />
            ) : (
                <NoData img={bad} imageWidth={100} text={t("no_report_data_today")} />
            )}

            <br />
            <ReportHistory initData={modal?.initData} params={`?course_id=${selectedClass?.courseId}&class_id=${selectedClass?.id}`} />
            <Modal
                zIndex={1001}
                centered={true}
                open={modal?.open}
                title={t("create_daily_report")}
                onCancel={handleCloseModal}
                footer={null}
                width={"90dvw"}
                ariaHidden="false"
            >
                <Report footer={true} mode={modal?.type} reportData={reportData} close={handleCloseModal} />
            </Modal>
        </div>
    );
}

export default React.memo(TeacherReport);
