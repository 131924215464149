import React, { useContext, useState, useRef, useEffect } from "react";
import { Row, Col, Typography, Select, Input, Button, notification, Modal } from "antd";
import rp_content from "../../assets/images/rp_content.png";
import rp_dis from "../../assets/images/rp_dis.png";
import rp_study from "../../assets/images/rp_study.png";
import rp_commend from "../../assets/images/rp_commend.png";
import rp_remind from "../../assets/images/rp_remind.png";
import { CommentOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { StoreContext } from "../../stores";
import MentionsStudent from "../../views/Home/components/MentionsStudent";
import { AREPORT } from "../../apis/report";
import { handleFetchApi } from "../../configs/apiRequest";
import { isAdmin, isTeacher } from "../../configs/enums";

const { TextArea } = Input;
const initReport = {
    content: "",
    class_homework: {
        content: "",
        url: "",
    },
    class_report_status: {
        discipline: "",
        discipline_content: "",
        study: "",
        study_content: "",
    },
    commend: "",
    admin_comment: "",
    criticize: "",
};

function Report({ initData, mode = "VIEW", close, footer }) {
    const { me, selectedClass } = useContext(StoreContext);
    const [reportData, setReportData] = useState(initData ?? initReport);
    const [viewMode, setViewMode] = useState(mode);
    const contentTextAreaRef = useRef(null);
    const commentTextarea = useRef(null);

    useEffect(() => {
        if (initData?.id !== reportData?.id) {
            setReportData(initData ?? initReport);
            setViewMode('VIEW')
        }
    }, [initData]);

    const onChange = (data) => {
        setReportData((prev) => ({ ...prev, ...data }));
    };

    const handlerCreateOrUpdate = async ({}) => {
        let response = {};

        if (!reportData?.content) {
            return notification.error({ message: "Nội dung không được để trống!" });
        }

        if (reportData?.id) {
            response = await handleFetchApi(
                AREPORT.update({
                    reportId: reportData?.id,
                    data: {
                        ...reportData,
                        class_id: selectedClass?.id,
                        course_id: selectedClass?.courseId,
                        teacher_id: me?.id,
                        type: "DAILY",
                        is_confirmed: false,
                    },
                })
            );
        } else {
            response = await handleFetchApi(
                AREPORT.createDaily({
                    data: {
                        ...reportData,
                        class_id: selectedClass?.id,
                        course_id: selectedClass?.courseId,
                        teacher_id: me?.id,
                        type: "DAILY",
                        is_confirmed: false,
                    },
                })
            );
        }

        if (response?.status === "S0000") {
            notification.success({
                message: t("success"),
                description: t("daily_report_created_successfully"),
            });
        } else {
            notification.error({
                message: t("error"),
                description: "Không thể tạo báo cáo vào lúc này.",
            });
        }
        setViewMode("VIEW");
        close();
    };

    const handlerAdminComment = async () => {
        const response = await handleFetchApi(AREPORT.comment({ reportId: reportData?.id, data: { admin_comment: reportData.admin_comment } }));
        if (response?.status === "S0000") {
            notification.success({
                message: t("success"),
            });
        } else {
            notification.error({
                message: t("error"),
                description: response?.message,
            });
        }
        setViewMode("VIEW");
        close();
    };

    const acceptReport = async () => {
        const response = await handleFetchApi(AREPORT.accept({ reportId: reportData?.id }));
        if (response?.status === "S0000") {
            notification.success({
                message: t("success"),
            });
        } else {
            notification.error({
                message: t("error"),
                description: response?.message,
            });
        }
        setViewMode("VIEW");
    };

    const ButtonCreateOrUpdate = () => (
        <Button className="btn-success" type="primary" onClick={handlerCreateOrUpdate} size="large">
            {mode === "CREATE" ? t("create_report") : t("confirm")}
        </Button>
    );

    const ButtonSendComment = () => (
        <Button className="btn-success" type="primary" onClick={handlerAdminComment} size="large">
            {t("confirm")}
        </Button>
    );

    const ButtonAdminComment = () => (
        <Button
            className="btn-info"
            type="primary"
            size="large"
            onClick={() => {
                setViewMode("COMMENT");
                setTimeout(() => {
                    commentTextarea.current?.focus();
                }, 200);
            }}
        >
            {t("comment")}
        </Button>
    );

    const ButtonAcceptReport = () => (
        <Button type="primary" size="large" className="btn-success" onClick={acceptReport}>
            Duyệt báo cáo
        </Button>
    );

    const ButtonEdit = () => (
        <Button
            type="primary"
            size="large"
            onClick={() => {
                setViewMode("EDIT");
                setTimeout(() => {
                    contentTextAreaRef.current?.focus();
                }, 200);
            }}
        >
            {t("update")}
        </Button>
    );

    const renderActionsButton = () => {
        if (!footer || reportData?.is_confirmed) return <></>;
        if (isTeacher(me)) {
            switch (viewMode) {
                case "VIEW":
                    return (
                        <>
                            <ButtonEdit />
                        </>
                    );
                case "EDIT":
                case "CREATE":
                    return (
                        <>
                            <ButtonCreateOrUpdate />
                        </>
                    );

                default:
                    return;
            }
        } else if (isAdmin(me)) {
            switch (viewMode) {
                case "VIEW":
                    return (
                        <>
                            <ButtonAdminComment />
                            <ButtonEdit />
                            <ButtonAcceptReport />
                        </>
                    );
                case "COMMENT":
                    return (
                        <>
                            <ButtonSendComment />
                        </>
                    );
                case "EDIT":
                    return (
                        <>
                            <ButtonCreateOrUpdate />
                        </>
                    );

                default:
                    return;
            }
        }
    };

    return (
        <Row gutter={[16, 16]} className="teacher_report" align="stretch">
            <Col xs={24} xl={15}>
                <div className="report-section card --padding --no-border report-content">
                    <Typography.Title level={5}>Nội dung học tập</Typography.Title>
                    <div className="flex__start__center gap">
                        <img alt="content" src={rp_content} />
                        <TextArea
                            ref={contentTextAreaRef}
                            autoFocus={viewMode === "EDIT"}
                            onChange={(e) => onChange({ content: e?.target?.value })}
                            bordered={false}
                            rows={reportData?.content ? Math.max(5, reportData.content.split("\n").length) : 5}
                            value={reportData?.content}
                            disabled={viewMode === "VIEW"}
                        />
                    </div>
                </div>
            </Col>
            <Col xs={24} xl={9}>
                <div className="report-section card --padding --no-border report-status">
                    <Typography.Title level={5}>Tình hình</Typography.Title>
                    <div className="flex__start__center gap" style={{ marginBottom: 12 }}>
                        <img alt="content" src={rp_dis} />
                        <div style={{ width: "100%" }}>
                            <div className="flex__start__center">
                                <span>Kỷ luật:</span>
                                <Select
                                    bordered={false}
                                    onChange={(e) => onChange({ class_report_status: { ...reportData?.class_report_status, discipline: e } })}
                                    size="small"
                                    placeholder={t("select_level")}
                                    value={reportData?.class_report_status?.discipline}
                                    disabled={viewMode === "VIEW"}
                                    showArrow={false}
                                >
                                    <Select.Option value="Good">{t("Good")}</Select.Option>
                                    <Select.Option value="Average">{t("Average")}</Select.Option>
                                    <Select.Option value="NeedsImprovement">{t("NeedsImprovement")}</Select.Option>
                                </Select>
                            </div>
                            <div className="flex__start__start">
                                <CommentOutlined style={{ marginTop: 10 }} />
                                <TextArea
                                    onChange={(e) =>
                                        onChange({ class_report_status: { ...reportData?.class_report_status, discipline_content: e?.target?.value } })
                                    }
                                    bordered={false}
                                    rows={2}
                                    placeholder="Chưa viết nhận xét"
                                    value={t(reportData?.class_report_status?.discipline_content)}
                                    disabled={viewMode === "VIEW"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex__start__center gap">
                        <img alt="content" src={rp_study} />
                        <div style={{ width: "100%" }}>
                            <div className="flex__start__center">
                                <span>Học tập:</span>
                                <Select
                                    bordered={false}
                                    onChange={(e) => onChange({ class_report_status: { ...reportData?.class_report_status, study: e } })}
                                    size="small"
                                    placeholder={t("select_level")}
                                    value={reportData?.class_report_status?.study}
                                    disabled={viewMode === "VIEW"}
                                    showArrow={false}
                                >
                                    <Select.Option value="Good">{t("Good")}</Select.Option>
                                    <Select.Option value="Average">{t("Average")}</Select.Option>
                                    <Select.Option value="NeedsImprovement">{t("NeedsImprovement")}</Select.Option>
                                </Select>
                            </div>
                            <div className="flex__start__start">
                                <CommentOutlined style={{ marginTop: 10 }} />
                                <TextArea
                                    onChange={(e) => onChange({ class_report_status: { ...reportData?.class_report_status, study_content: e?.target?.value } })}
                                    bordered={false}
                                    rows={2}
                                    value={t(reportData?.class_report_status?.study_content)}
                                    placeholder="Chưa viết nhận xét"
                                    disabled={viewMode === "VIEW"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={24} xl={24}>
                <div className="report-section card --padding --no-border report-homework">
                    <Typography.Title level={5}>Bài tập về nhà</Typography.Title>
                    <TextArea
                        onChange={(e) =>
                            onChange({
                                class_homework: {
                                    ...reportData.class_homework,
                                    content: e?.target?.value,
                                },
                            })
                        }
                        bordered={false}
                        rows={3}
                        value={reportData?.class_homework?.content}
                        disabled={viewMode === "VIEW"}
                    />
                    {/* <Typography.Text>Đường dẫn bài tập về nhà</Typography.Text>
                    <Input size="large" bordered={false} placeholder="Dán đường dẫn làm bài tập tại đây" /> */}
                    {/* <Typography.Text>
                        {reportData?.class_homework?.content?.split("\n").map((item, index) => (
                            <span key={index}>
                                {item}
                                {index < reportData.class_homework.content?.split("\n").length - 1 && <br />}
                            </span>
                        ))}
                    </Typography.Text> */}
                </div>
            </Col>
            <Col xs={24} xl={12}>
                <div className="report-section card --padding report-commend">
                    <Typography.Title level={5}>Tuyên dương</Typography.Title>
                    <div className="flex__between__center gap">
                        {/* <Typography.Text>
                            {reportData?.commend?.split("\n").map((item, index) => (
                                <span key={index}>
                                    {item}
                                    {index < reportData.commend.split("\n").length - 1 && <br />}
                                </span>
                            ))}
                        </Typography.Text> */}
                        <MentionsStudent
                            disabled={viewMode === "VIEW"}
                            onChange={onChange}
                            type="GOOD"
                            classId={selectedClass?.id ?? reportData?.school_class?.id}
                            initValue={reportData?.commend}
                        />
                        <img alt="content" src={rp_commend} />
                    </div>
                </div>
            </Col>
            <Col xs={24} xl={12}>
                <div className="report-section card --padding report-criticize">
                    <Typography.Title level={5}>Nhắc nhở</Typography.Title>
                    <div className="flex__between__center gap">
                        {/* <Typography.Text>
                            {reportData?.criticize?.split("\n").map((item, index) => (
                                <span key={index}>
                                    {item}
                                    {index < reportData.criticize?.split("\n").length - 1 && <br />}
                                </span>
                            ))}
                        </Typography.Text> */}

                        <MentionsStudent
                            disabled={viewMode === "VIEW"}
                            onChange={onChange}
                            type="BAD"
                            classId={selectedClass?.id ?? reportData?.school_class?.id}
                            initValue={reportData?.criticize}
                        />
                        <img alt="content" src={rp_remind} />
                    </div>
                </div>
            </Col>
            {(me?.role !== "ROLE_USER" || !reportData?.is_confirmed) && (
                <Col span={24}>
                    <div className="report-section card --padding report-comment">
                        <Typography.Title level={5}>Nhận xét của quản lý</Typography.Title>
                        <Input.TextArea
                            ref={commentTextarea}
                            value={reportData?.admin_comment}
                            placeholder="Viết nhận xét"
                            style={{ padding: 0 }}
                            disabled={!isAdmin(me) || reportData?.is_confirmed || viewMode === "VIEW"}
                            bordered={false}
                            rows={2}
                            onChange={(e) =>
                                onChange({
                                    admin_comment: e?.target?.value,
                                })
                            }
                        />
                    </div>
                </Col>
            )}
            <Col xs={24} xl={24}>
                <div className="flex__end__center gap">{renderActionsButton()}</div>
            </Col>
        </Row>
    );
}

export default Report;
